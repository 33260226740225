<template>
  <div class="home-section zen-experience-block">
    <div class="container">
      <div class="body">
        <h2>Introducing <b>Zen 3,</b><br />the <b>experience</b> platform</h2>

        <div class="zen3-video">
          <VideoPlayer video-url="/assets/video/zen3-explainer-overview.webm"
            ><img src="/assets/zen3-video-cover.png" alt="zenapptic experience video"
          /></VideoPlayer>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.zen-experience-block {
  background-color: #000000 !important;
  background-image: url(/assets/zen-experience/zen3-bground.webp);
  background-position: center;
  background-size: cover;

  .container {
    margin: auto;
    max-width: 1200px;

    .body {
      align-items: center;
      display: flex !important;
      gap: 5rem;
      padding: 5rem;

      h2 {
        color: white;
        flex: 4;
        font-weight: 200;
        text-align: left;
      }

      @media (max-width: 767px) {
        flex-direction: column;
        h2 {
          text-align: center;
        }
      }

      .zen3-video {
        flex: 2;
      }
    }
  }
}
</style>
