<template>
  <div class="vnye-2025-feature">
    <div class="feature-content">
      <div class="logos">
        <div class="vnye-logo">
          <img src="/assets/vnye-2025/vnye-2025-logo.webp" alt="VNYE 2025" />
        </div>
        <div class="ots-logo">
          <div class="logos-top">
            <div>
              <img
                src="/assets/vnye-2025/vnye-2025-logo.webp"
                alt="VNYE 2025"
                class="vnye-mobile"
              />
            </div>
            <div><img src="/assets/vnye-2025/ots-logo.webp" alt="One Times Square" /></div>
          </div>

          <h2>
            We're proud to<br />power the virtual<br />New Year's Eve<br />Livestream and<br />Celebrations<br />in
            Times Square
          </h2>

          <div class="link">
            <span><img src="/assets/vnye-2025/left-pointer.webp" alt="VNYE 2025" /></span>
            <a href="https://www.vnye.com" target="_blank"><span>Celebrate</span> here</a>
            <span><img src="/assets/vnye-2025/right-pointer.webp" alt="VNYE 2025" /></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.vnye-2025-feature {
  background: url("/assets/vnye-2025/vnye-2025-background.webp");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;

  @media (max-width: 767px) {
    background-position: 75% top;
  }

  .feature-content {
    color: white;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    margin: auto;
    max-width: 1366px;
    padding: 6rem 0;

    width: 100%;

    @media (max-width: 830px) {
      padding: 6rem 1rem;
    }

    .logos {
      display: flex;
      gap: 3rem;

      .vnye-logo {
        @media (max-width: 767px) {
          display: none;
        }
        img {
          width: 200px;
        }
      }

      .ots-logo {
        display: flex;
        flex-direction: column;
        gap: 5rem;
        padding-top: 6%;

        .logos-top {
          @media (max-width: 767px) {
            align-items: center;
            display: flex;
            gap: 4rem;
            img {
              width: 125px;
            }
          }
          .vnye-mobile {
            width: 125px;
            @media (min-width: 768px) {
              display: none;
            }
          }
        }

        h2 {
          animation: gradient 8s ease infinite;

          background: linear-gradient(
            -65deg,
            rgb(224, 154, 24),
            rgb(235, 211, 136),
            rgba(238, 200, 75, 1),
            rgb(221, 157, 19)
          );
          -webkit-background-clip: text;
          background-clip: text;
          background-size: 200% 200%;
          filter: drop-shadow(0px 0px 5px rgba(black, 1));
          font-size: clamp(3rem, 6vw, 7.4rem);
          font-weight: 700;
          line-height: 0.85;
          -webkit-text-fill-color: transparent;
          text-transform: uppercase;
        }
      }

      .link {
        align-items: center;
        display: flex;
        gap: 1.5rem;
        margin-left: -2rem;
        a {
          color: #ecca5a;
          font-size: clamp(2rem, 4vw, 5rem);
          font-weight: 700;
          text-decoration: none;
          text-transform: uppercase;

          span {
            color: white;
          }
        }
      }
    }
  }
}
</style>
