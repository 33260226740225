<script setup lang="ts">
const scrollingToTarget = useScrollingToTarget();
let sections: NodeListOf<HTMLElement>;
let hashObserver: IntersectionObserver;

const appMounted = useState("appMounted");
const { sendScreenView } = useAnalytics();
if (appMounted.value) sendAnalytics();
watch(appMounted, () => sendAnalytics);

function sendAnalytics() {
  sendScreenView({ type: "PageView", target: "index" }, true);
}

onMounted(() => {
  hashObserver = new IntersectionObserver(
    (entries: IntersectionObserverEntry[]) => {
      if (!scrollingToTarget.value) {
        if (entries[0].isIntersecting) {
          const id = entries[0].target.id;
          sendScreenView({
            type: "view",
            target: id,
          });
        }
      }
    },
    { rootMargin: "-20% 0px -20% 0px" }
  );

  sections = document.querySelectorAll("section");
  sections.forEach((section) => {
    hashObserver.observe(section);
  });
});
</script>

<template>
  <main class="container home">
    <section id="feature" class="observable">
      <div class="anchor" />
      <feature />
    </section>

    <section id="aboutUs" class="observable">
      <div class="anchor" />
      <about />
    </section>

    <section id="goodRiddanceFeature" class="observable">
      <div class="anchor" />
      <GoodRiddanceFeature />
    </section>

    <section id="vnye2025Feature" class="observable">
      <div class="anchor" />
      <Vnye2025Feature />
    </section>

    <section id="solutions" class="observable">
      <div class="anchor" />
      <SolutionsFeature />
    </section>

    <section id="platform" class="observable">
      <div class="anchor" />
      <ZenExperienceVideo />
    </section>

    <section id="whychoose" class="observable">
      <div class="anchor" />
      <platform />
    </section>

    <section id="partners" class="observable">
      <div class="anchor" />
      <Partners />
    </section>

    <section id="contact" class="observable">
      <div class="anchor" />
      <LazyContact />
    </section>
  </main>
</template>
